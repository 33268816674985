<template>
  <div class="bankList">
    <van-sticky>
      <van-nav-bar title="银行卡管理" left-arrow @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>

    <div v-if="form.bankAccountNo" class="bankCard" @click="viewBankCard">
      <!-- <div class="statusSty">审核中</div> -->
      <div class="bankInfo">
        <div class="bankLogo">
          <img src="../../img/categories/bankLogo.png" alt=""/>
        </div>
        <div class="bankName">
          <div>{{ form.bankName || '-' }}</div>
          <span>储蓄卡</span>
        </div>
      </div>
      <div class="bankID">{{ bankCardID(form.bankAccountNo) }}</div>
      <div class="btn-wrap">
        <van-button
            round color="#5885ee" icon="delete-o"
            @click.stop="del"
        >
          删除
        </van-button>
      </div>
    </div>
    <van-empty
        v-if="!loading && !form.bankAccountNo"
        description="请添加银行卡"
    />
    <!-- <div v-if="form.bankAccountNo&&form.bankName" style="margin-top: 28%">
      <van-empty description="还未绑定银行卡" />
    </div> -->
    <div v-if="!loading && !form.bankAccountNo" class="replaceBank" @click="addAndEditBank">
      <van-button type="primary" round block
      > 添加银行卡
      </van-button
      >
    </div>

    <van-dialog
        v-model="viewBankCardShow"
        title="银行卡详情"
        confirm-button-text="确认"
    >
      <van-cell-group style="padding: .05rem 0;" inset>
        <van-cell title="开户行" :value="form.bankName" />
        <van-cell title="银行卡号" :value="form.bankAccountNo" />
        <van-cell title="预留手机号" :value="form.bankMobile" />
      </van-cell-group>
    </van-dialog>

  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import { delUserBankCard, getUserInfo } from '@/api/user';
import { getBankList } from '@/api/user';

export default {
  name: 'bankList',
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: true,
      bankCard: '',
      bankList: [],
      iframeUrl: '',
      showPicker: false,

      inputType: 'password',
      form: {
        bank: '', // 开户行
        bankName: '',
        bankAccountNo: '', // 银行卡号
        bankMobile: '',
      },
      saving: false,
      reSubmitCountDownTime: 0,
      viewBankCardShow: false,
    };
  },

  created() {
    let countDown = window.localStorage.getItem('userInfoReSubmitCountDown') || 0;
    if (countDown && Date.now() < countDown) {
      this.reSubmitCountDownTime = +countDown - Date.now();
    }

    this.initBankCard();
  },
  methods: {
    async initBankCard() {
      this.loading = true;
      Toast.loading({});
      await getUserInfo().then(res => {
        this.loading = false;
        Toast.clear();
        const userInfo = res || {};
        Object.assign(this.form, {
          bank: userInfo.bank || '',
          bankAccountNo: userInfo.bankAccountNo || '',
          bankMobile: userInfo.bankMobile || '',
        });
      });

      getBankList().then(res => {
        this.bankList = res || [];
        let bankName = '';
        for (let bank of this.bankList) {
          if (bank.value === this.form.bank) {
            bankName = bank.label;
            break;
          }
        }
        this.form.bankName = bankName;
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    bankCardID(value) {
      var reg = /^(\d{0})\d+(\d{4})$/;
      let bankCard = value.replace(reg, '•••• •••• •••• •••• $2');
      // ●●●●
      console.log(bankCard);
      return bankCard;
    },
    viewBankCard() {
      console.log({...this.form})
      this.viewBankCardShow = true
    },
    del() {
      Dialog.confirm({
        title: `是否删除银行卡？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            Toast.loading({});
            delUserBankCard({
              ...this.form,
            }).then(res => {
              Toast.clear();
              if (res === true) {
                this.initBankCard();
              }
            });
          }
          done();
        },
      }).catch(() => {
      });
    },
    addAndEditBank() {
      this.$router.push({
        path: '/addAndEditBank',
        query: {
          // title:'更换',
          title: '添加',
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.bankList {
  background-color: #f3f3f3;
  padding-bottom: 120px;

  .bankCard {
    position: relative;
    background-color: #5885ee;
    margin: 16px 16px 0;
    border-radius: 4px;
    padding: 30px 0 14px 20px;
    color: #fff;

    .statusSty {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      font-weight: 700;
    }

    .bankInfo {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .bankLogo {
        width: 32px;
        height: 32px;
        margin-right: 12px;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .bankName {
        font-size: 16px;

        > span {
          font-size: 12px;
        }
      }
    }

    .bankID {
      margin-left: 36px;
    }

    .btn-wrap {
      margin-top: 20px;
    }
  }

  ::v-deep .replaceBank {
    position: fixed;
    bottom: 0px;
    left: 14px;
    width: 93%;
    background-color: #f3f3f3;
    height: 80px;
    padding-top: 15px;

    .van-button--primary {
      background-color: #5885ee;
      border: #5885ee;
    }
  }
}

::v-deep .van-nav-bar {
  background-color: #f8f8f8;

  .van-icon {
    color: #212121;
    font-size: 18px;
  }
}
</style>
